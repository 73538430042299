<template>
  <div class="all-video-lectures-page">
    <v-row justify="center">
      <v-col md="6">
        <intract-smart-list
          paginated
          ref="all-video-lectures-smart-list"
          class="mt-4"
          :endpoint="getAllLecturesEndpoint"
          :item-options="{ title: 'title' }"
        >
          <template v-slot:list-item="{ item: lectureItem }">
            <div>
              <v-list-item
                class="non-focused"
                @click="
                  $router.push({
                    name: 'VideoLecture',
                    params: {
                      lectureId: lectureItem.id,
                      lectureName: lectureItem.title,
                    },
                  })
                "
              >
                <v-list-item-title class="font-weight-medium my-5">{{
                  lectureItem.title
                }}</v-list-item-title>
                <v-list-item-icon
                  ><v-icon>mdi-arrow-right</v-icon></v-list-item-icon
                >
              </v-list-item>
              <v-divider class="mx-4"></v-divider>
            </div>
          </template>
        </intract-smart-list>
      </v-col>
    </v-row>

    <v-fab-transition>
      <v-btn
        v-if="!isLoading && !currentUser.is_student"
        color="primary"
        @click="videoLectureCreateEditSheet.visible = true"
        dark
        right
        bottom
        fixed
        fab
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <intract-create-edit-sheet
      persistent
      title="Create Video Lecture"
      :visible="videoLectureCreateEditSheet.visible"
      @close="videoLectureCreateEditSheet.visible = false"
      :fields="formFields"
      submit-button-text="Post video"
      :endpoint="endpoints.videolecturesViewSet"
      :data-object="videoLectureCreateEditSheet.obj"
      :create-success-message="videoLectureCreateEditSheet.createSuccessMessage"
      @objectCreated="lectureCreated"
      @updateObject="(obj) => (videoLectureCreateEditSheet.obj = obj)"
      :enable-media="!(videoLectureCreateEditSheet.obj.link || videoLectureCreateEditSheet.obj.video_file)"
    />
  </div>
</template>

<script>
import Mixins from "@utils/mixins";
import EventBus from "@utils/event_bus";
import IntractSmartList from "@components/generics/IntractSmartList";
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";

export default {
  name: "AllVideoLectures",
  mixins: [Mixins.essentials, Mixins.handleAppBarTitle, Mixins.pullToRefresh],

  components: {
    IntractSmartList,
    IntractCreateEditSheet,
  },

  data() {
    return {
      appBarTitle: null,
      showDeleteDialog: false,
      toBeDeletedItem: null,
      moduleId: null,
      videoLectureCreateEditSheet: {
        visible: false,
        createSuccessMessage: "Video Lecture created successfully!",
        obj: {
          module: this.$route.params.moduleId,
          creator: null,
          faculty: null,
          video_file: null,
        },
      },
    };
  },

  methods: {
    async onRefresh() {
      if (this.$refs["all-video-lectures-smart-list"]) {
        this.$refs["all-video-lectures-smart-list"].getItems();
      }
    },

    async lectureCreated(lecture) {
      this.onRefresh();
      this.$router.push({
        name: "VideoLecture",
        params: { lectureId: lecture.id, lectureName: lecture.title },
      });
    },

    setListeners() {
      EventBus.$on("video_lectures__lecture_deleted", (id) =>
        this.$refs["all-video-lectures-smart-list"].removeItem(id)
      );
    },
  },

  computed: {
    formFields() {
      return {
        title: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Title",
          required: true,
          md: 12,
          max: 255,
        },
        description: {
          fieldType: CreateEditFieldTypes.TEXTAREA,
          label: "Description (optional)",
          md: 12,
          required: false,
          max: 3000,
        },
        video_file: {
          fieldType: CreateEditFieldTypes.VIDEOUPLOAD,
          multiple: false,
          required: false,
          label: "Upload a Video",
          helper: "Upload a video from device",
          valid: () => {
            return (this.videoLectureCreateEditSheet.obj.video_file != null || this.videoLectureCreateEditSheet.obj.link != null)
          }
        },
        link: {
          fieldType: CreateEditFieldTypes.LINKUPLOAD,
          multiple: false,
          required: this.videoLectureCreateEditSheet.obj.video_file ? false : true,
          label: "Video",
          helper: "Link a YouTube, Google Drive Video or add a Custom Video Link",
          valid: () => {
            return (this.videoLectureCreateEditSheet.obj.video_file != null || this.videoLectureCreateEditSheet.obj.link != null)
          }
        },
      };
    },

    getAllLecturesEndpoint() {
      console.log("getAllLecturesEndpoint changed");
      var url = this.Helper.addUrlParams(this.endpoints.videolecturesViewSet, [
        "module=" + this.moduleId,
      ]);
      return url;
    },
  },

  created() {
    this.moduleId = this.$route.params.moduleId;
    this.setListeners();
    this.videoLectureCreateEditSheet.obj.creator = this.currentUser.id;
    this.videoLectureCreateEditSheet.obj.faculty = this.currentUser.id;
    this.appBarTitle = this.$route.params.moduleName ?? "Module Lectures";
  },

  beforeRouteLeave(to, from, next) {
    if (to.name != "VideoLecture") {
      //kill component
      EventBus.$emit("keep_alive__destroy_view", "AllVideoLectures");
    }
    next();
  },
};
</script>

<style>
</style>