var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all-video-lectures-page"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"6"}},[_c('intract-smart-list',{ref:"all-video-lectures-smart-list",staticClass:"mt-4",attrs:{"paginated":"","endpoint":_vm.getAllLecturesEndpoint,"item-options":{ title: 'title' }},scopedSlots:_vm._u([{key:"list-item",fn:function(ref){
var lectureItem = ref.item;
return [_c('div',[_c('v-list-item',{staticClass:"non-focused",on:{"click":function($event){return _vm.$router.push({
                  name: 'VideoLecture',
                  params: {
                    lectureId: lectureItem.id,
                    lectureName: lectureItem.title,
                  },
                })}}},[_c('v-list-item-title',{staticClass:"font-weight-medium my-5"},[_vm._v(_vm._s(lectureItem.title))]),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-arrow-right")])],1)],1),_c('v-divider',{staticClass:"mx-4"})],1)]}}])})],1)],1),_c('v-fab-transition',[(!_vm.isLoading && !_vm.currentUser.is_student)?_c('v-btn',{attrs:{"color":"primary","dark":"","right":"","bottom":"","fixed":"","fab":""},on:{"click":function($event){_vm.videoLectureCreateEditSheet.visible = true}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1),_c('intract-create-edit-sheet',{attrs:{"persistent":"","title":"Create Video Lecture","visible":_vm.videoLectureCreateEditSheet.visible,"fields":_vm.formFields,"submit-button-text":"Post video","endpoint":_vm.endpoints.videolecturesViewSet,"data-object":_vm.videoLectureCreateEditSheet.obj,"create-success-message":_vm.videoLectureCreateEditSheet.createSuccessMessage,"enable-media":!(_vm.videoLectureCreateEditSheet.obj.link || _vm.videoLectureCreateEditSheet.obj.video_file)},on:{"close":function($event){_vm.videoLectureCreateEditSheet.visible = false},"objectCreated":_vm.lectureCreated,"updateObject":function (obj) { return (_vm.videoLectureCreateEditSheet.obj = obj); }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }